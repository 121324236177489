<template>
  <div>
    <b-form-group :label="label" :class="formGroupClass">
      <div class="position-relative">
        <b-input-group :prepend="prepend" :append="append">
          <b-form-input
            :id="id"
            v-model="model"
            :input="inputValue()"
            :state="state != undefined ? state : null"
            :type="type"
            @keypress="changeInput"
            @keyup.enter="onEnter"
            :placeholder="placeholder ? placeholder : label"
            :readonly="readonly"
          ></b-form-input>
        </b-input-group>
        <div v-if="hasSlot" class="input-icon-wrap">
          <slot></slot>
        </div>

        <b-form-invalid-feedback
          :id="`${id}-input-live-feedback`"
          v-if="errorMessage || (vError && vError.$error === true)"
        >
          <template v-if="errorMessage">
            {{ errorMessage }}
          </template>
          <template v-if="vError && vError.$error === true">
            <span v-if="vError.required === false">
              Bu alanı doldurmanız gerekmektedir.
            </span>
            <span v-if="vError.email === false">
              Bu alana geçerli bir eposta adresi girmeniz gerekmektedir.
            </span>
          </template>
        </b-form-invalid-feedback>
      </div>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    label: String,
    id: String,
    state: { type: Boolean, default: undefined },
    placeholder: String,
    defaultValue: { type: String, default: '' },
    type: { type: String, default: 'text' },
    readonly: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' },
    vError: { type: Object, default: null },
    prepend: { type: String, default: '' },
    append: { type: String, default: '' },
    formGroupClass: { type: String, default: '' },
  },
  name: 'Input',
  data() {
    return {
      model: '',
    };
  },
  created() {
    this.setDefaultValue();
  },
  computed: {
    hasSlot() {
      return !!this.$slots.default;
    },
  },
  methods: {
    setDefaultValue() {
      this.model = this.defaultValue != '' ? this.defaultValue : '';
    },
    inputValue() {
      this.$emit('input', this.model);
    },
    changeInput() {
      if (this.state != null) {
        this.$emit('formReset', true);
        setTimeout(() => {
          this.$emit('formReset', false);
        }, 200);
      }
    },
    onEnter() {
      this.$emit('click-enter');
    },
  },
  watch: {
    defaultValue() {
      this.setDefaultValue();
    },
    value(newVal) {
      this.model = newVal;
    },
  },
};
</script>

<style lang="scss">
.input-icon-wrap i {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 13px;
  line-height: 13px;
  color: #b5b5b5;
}
</style>
